import React, { useEffect, useState, useRef } from "react";

import AnimatedLetters from "../AnimatedLetters";
import "./index.scss";
import { motion as m, useInView, useAnimation } from 'framer-motion'


const Portfolio = () => {
    const [ letterClass, setLetterClass ] = useState( 'text-animate' );
    const ref = useRef( null );
    const isView = useInView( ref, { once: true } );

    const mainAnimation = useAnimation();

    useEffect( () => {
        const timer = setTimeout( () => {
            setLetterClass( 'text-animate-hover' );
        }, 3000 );

        return () => {
            clearTimeout( timer );
        }
    },[isView] );






    useEffect( () => {
        if ( isView ) {
            mainAnimation.start( 'visible' )
        }
    }, [ isView ] )

    return (
        <m.section className="container portfolio-page " id="portfolio"
            ref={ ref } variants={ {
                hidden: { opacity: 0, y:100},
                visible: { opacity: 1, y: 0 }
            } }
            initial="hidden"
            animate={ mainAnimation }
            transition={ { duration: .5, delay: .2 } }
        >
            <div className="text-zone" >
                <h1 className="page-title">
                    <AnimatedLetters
                        letterClass={ letterClass }
                        strArray={ "Portfolio".split( "" ) }
                        idx={ 15 }
                    />
                </h1>
            </div>
            <div className="images-container">
                <div className="image-box" >
                    <img
                        src={ '/breadit.png' }
                        className="portfolio-image"
                        alt="portfolio" />
                    <div className="content">
                        <p className="title">Breadit </p>
                        <h4 className="description">Reddit-clone ( ton of fetures ) </h4>
                        <button
                            className="btn"
                            onClick={ () => window.open( 'https://bredit.vercel.app' ) }
                        >View</button>
                    </div>
                </div>
                <div className="image-box" >
                    <img
                        src={ '/Screenshot 2023-06-16 165631.png' }
                        className="portfolio-image"
                        alt="portfolio" />
                    <div className="content">
                        <p className="title">Ecommerce </p>
                        <h4 className="description">E-commerce website allows updating data and adding to Cart proceeding to checkout</h4>
                        <button
                            className="btn"
                            onClick={ () => window.open( 'https://front-anwar.vercel.app' ) }
                        >View</button>
                    </div>
                </div>
                <div className="image-box" >
                    <img
                        src={ '/Screenshotdashbordpng.png' }
                        className="portfolio-image"
                        alt="portfolio" />
                    <div className="content">
                        <p className="title">Dashboard Ecommerce</p>
                        <h4 className="description"> Basic dashboard for Ecommerce allow Posting, Editing, Deleting and tracking the Products & Orders </h4>
                        <button
                            className="btn"
                            onClick={ () => window.open( 'https://dashboard-anwar.vercel.app' ) }
                        >View</button>
                    </div>
                </div>
                <div className="image-box" >
                    <img
                        src={ '/Screenshot sketch.png' }
                        className="portfolio-image"
                        alt="portfolio" />
                    <div className="content">
                        <p className="title">sketch design</p>
                        <h4 className="description"> design single static page  </h4>
                        <button
                            className="btn"
                            onClick={ () => window.open( 'https://sketch-web-clone.vercel.app/' ) }
                        >View</button>
                    </div>
                </div>
                <div className="image-box" >
                    <img
                        src={ '/bonsai.png' }
                        className="portfolio-image"
                        alt="portfolio" />
                    <div className="content">
                        <p className="title">bonsai pricing design</p>
                        <h4 className="description"> design single static page  </h4>
                        <button
                            className="btn"
                            onClick={ () => window.open( 'https://bonsai-sigma.vercel.app/' ) }
                        >View</button>
                    </div>
                </div>
            </div>
        </m.section>
    );
}

export default Portfolio;