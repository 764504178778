import './index.scss'
import { useState } from 'react'
import LogoSubtitle from '../../assets/images/[removal.ai]_tmp-6489e114c1218.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faGithub,

} from '@fortawesome/free-brands-svg-icons'
import {
  faCode,
  faHome,
  faUser,
  faEnvelope,
  faSuitcase,
  faBars,
  faClose,
  faFile
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  const [ showNav, setShowNav ] = useState( false );

  return (
    <div className="nav-bar">
      <a
        className="logo"
        href="#home"
        onClick={ () => setShowNav( false ) }>
        <img className="sub-logo" src={ LogoSubtitle } alt="slobodan" />
      </a>
      <nav className={ showNav ? 'mobile-show' : '' }>
        <a
          exact="true"
          activeclassname="active"
          href="#home"
          onClick={ () => setShowNav( false ) }>
          <FontAwesomeIcon icon={ faHome } color="#4d4d4e" />
        </a>
        <a
          activeclassname="active"
          className="about-link"
          href="#about"
          onClick={ () => setShowNav( false ) }>
          <FontAwesomeIcon icon={ faUser } color="#4d4d4e" />
        </a>
        <a
          activeclassname="active"
          className="skills-link"
          href="#skills"
          onClick={ () => setShowNav( false ) }>
          <FontAwesomeIcon icon={  faCode } color="#4d4d4e" />
        </a>
        <a
          activeclassname="active"
          className="portfolio-link"
          href="#portfolio"
          onClick={ () => setShowNav( false ) }
        >
          <FontAwesomeIcon icon={ faSuitcase } color="#4d4d4e" />
        </a>
        <a
          activeclassname="active"
          className="contact-link"
          href="#contact"
          onClick={ () => setShowNav( false ) }
        >
          <FontAwesomeIcon icon={ faEnvelope } color="#4d4d4e" />
        </a>
        <a
          download
          activeclassname="active"
          className="resume-link"
          href="/anwar_kamal.pdf"
          onClick={ () => setShowNav( false ) }
        >
          <FontAwesomeIcon icon={ faFile } color="#4d4d4e" />
        </a>
        <FontAwesomeIcon
          onClick={ () => setShowNav( false ) }
          icon={ faClose }
          color="#ffd700"
          size="3x"
          className='close-icon' />
        
      </nav>
      <ul >
        <li>
          <Link
            to="https://www.linkedin.com/in/anwar-kamal-mohamed//"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={ faLinkedin }
              color="#4d4d4e"
              className="anchor-icon"
            />
          </Link>
        </li>
        <li>
          <Link
            to="https://github.com/AnwarKXC"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={ faGithub }
              color="#4d4d4e"
              className="anchor-icon"
            />
          </Link>
        </li>
      </ul>

      <FontAwesomeIcon
        onClick={ () => setShowNav( true ) }
        icon={ faBars }
        color="#ffd700"
        size="3x"
        className='hamburger-icon' />
    </div>
  )
}

export default Sidebar
