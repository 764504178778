import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/[removal.ai]_tmp-6489e114c1218.png'
import Logo from './Logo'
import './index.scss'


const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = ['n', 'w', 'a', 'r', ]
  const jobArray = [
    'w',
    'e',
    'b',
    '  ',
    'd',
    'e',
    'v',
    'e',
    'l',
    'o',
    'p',
    'e',
    'r',
    '.',
  ]


  useEffect( () => {

    let timeoutId = setTimeout( () => {
      setLetterClass( 'text-animate-hover' )
    }, 4000 )

    return () => {
      clearTimeout( timeoutId )
    }
  }, [] )






  return (
    
      <section className="container home-page" id='home'>
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <img
              src={LogoTitle}
              alt="JavaScript Developer Anwar, Web Developer Anwar"
            />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={22}
            />
          </h1>
          <h2>MERN stack / JavaScript Expert </h2>
          <a href="#contact" className="flat-button">
            CONTACT ME
          </a>
        </div>
      <Logo />
      <Loader type="pacman" />
      </section>

      
  )
}

export default Home
