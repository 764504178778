import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Layout from './components/Layout'
import Portfolio from './components/Portfolio'
import './App.scss'
import Skills from './components/Skills'
function App () {
  return (
    <>
      <Layout>
        <Home />
        <About />
        <Skills/>
        <Portfolio />
        <Contact />
      </Layout>
    </>
  )
}

export default App
