import './index.scss'
import AnimatedLetters from '../AnimatedLetters';
import { useState, useEffect,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
   faNode,
   faCss3,
   faGitAlt,
   faHtml5,
   faJsSquare,
   faReact,
   faSass,
} from '@fortawesome/free-brands-svg-icons'
import { motion as m, useInView, useAnimation } from 'framer-motion'


const Skills = () => {
   const [ letterClass, setLetterClass ] = useState( 'text-animate' );
   useEffect( () => {
      const timer = setTimeout( () => {
         setLetterClass( 'text-animate-hover' );
      }, 3000 );

      return () => {
         clearTimeout( timer );
      }
   } );

   const ref = useRef( null );
   const mainAnimation = useAnimation();
   const isView = useInView( ref, { once: true } );


   useEffect( () => {
      if ( isView ) {
         mainAnimation.start( 'visible' )
      }
   }, [ isView ] )


   return (
      <m.section id="skills" className='container skills-page'
         ref={ ref } variants={ {
            hidden: { opacity: 0, y: 200 },
            visible: { opacity: 1, y: 0 }
         } }
         initial="hidden"
         animate={ mainAnimation }
         transition={ { duration: 1, delay: .2 } }
      >
         <div className='text-zone'>
            <h1 className="page-title">
               <AnimatedLetters
                  letterClass={ letterClass }
                  strArray={ [ "Skills", "  ", "&", " ","Experience "] }
                  idx={ 15 }
               />
            </h1>
            <p>Expert in full stack development including technologies like <b>HTML5</b> , <b>CSS3</b> ,
               <b> JavaScript </b> , <b> TypeScript </b> , <b> Node </b> , <b> Express </b> , <b> Nest.js </b> , <b> React </b> , <b> Redux </b> , <b>Next</b> , <b> Tailwind </b> , <b>Material-UI</b> , <b>Sass</b> , <b>REST</b> , <b>MongooDB</b>, <b>MySQL</b> , <b> Git</b> , <b>Jest</b> , <b>Amazon (S3)</b>
               &nbsp; etc.
            </p>
            <p>
               I'm not a designer but i have a good sense of aesthetics, and experience in
               responsive, mobile-first web design. I put special effort into optimizing my code
               and providing the best user experience. I would love to give you any kind of
               Support also after the project's completion. I guarantee a commitment during
               work on your project.
            </p>
            <p>
               Visit my <a href='https://www.linkedin.com/in/anwar-kamal-mohamed/'>LinkedIn </a>profile for more details. Also you can checkout my <a href='https://github.com/AnwarKXC'>GitHub</a>.
            </p>

         </div>

         <div className='skills'>
            <div className='obj-skills'>
               <div className="face1">
                  <FontAwesomeIcon icon={ faHtml5 } color="#F06529" />
               </div>
               <div className="face">
                  <img src="/mysql-501.svg " width={120} alt='mysql'></img>
               </div>
               <div className="face2">
                  <FontAwesomeIcon icon={ faCss3 } color="#28A4D9" />
               </div>
               <div className="face3">
                  <FontAwesomeIcon icon={ faJsSquare } color="#EFD81D" />
               </div>
               <div className="face4">
                  <FontAwesomeIcon icon={ faReact } color="#5ED4F4" />
               </div>
               <div className="face5">
                  <FontAwesomeIcon icon={ faGitAlt } color="#EC4D28" />
               </div>
               <div className="face6">
                  <img src="/png-transparent-amazon-aws-logo.png" alt="Redux logo" width="100" height="100" />
               </div>
               <div className="face7">
                  <FontAwesomeIcon icon={ faSass } color='#C76494' />
               </div>
               <div className="face8">
                  <img  src="https://d33wubrfki0l68.cloudfront.net/97f337956b87f4589dbf68591f22f5f3dacf2736/55f2a/img/redux_white.svg" alt="Redux logo" width="100" height="100"  />
               </div>
               <div className="face9">
                  <img  src="/eyecatch_NextJS_1200x630-960x504-removebg-preview.png" alt="Redux logo" width="100" height="100"  />
               </div>

               <div className="face">
                  <FontAwesomeIcon icon={ faNode } color='#43853D' />
               </div>

            </div>

         </div>

      </m.section >
   )


}


export default Skills;