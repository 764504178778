import Sidebar from '../Sidebar/'
import './index.scss'

const Layout = ({children}) => {
  return (
    <div className="App">
      <Sidebar />
      <div className="page">
        <span className="tags top-tags">&lt;body&gt;</span>

        {children}
        <span className=" bottom-tags">
          &lt;/body&gt;
          <br />
          <span className="bottom-tag-html">&lt;/html&gt;
            <br></br>
          </span>
          

        </span>
      </div>
    </div>
  )
}

export default Layout
