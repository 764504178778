import { useEffect, useState } from 'react'
import {
  faNode,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import { motion as m, useInView, useAnimation} from 'framer-motion'
import { useRef } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])


  const ref = useRef( null );
  const mainAnimation = useAnimation();
  const isView = useInView( ref, { once: true } );


  useEffect( () => {
    if ( isView ) {
      mainAnimation.start( 'visible')
    }
  }, [ isView ] )

  return (
    <m.section
      variants={ {
        hidden: { opacity: 0,y:100},
        visible: { opacity:1,y:0 }
      } }
      ref={ ref }
      initial="hidden"
      animate={ mainAnimation }    
      transition={ { delay: .2, duration: .7, transformStyle: 'preserve-3d', }}
      id='about'>
      
      <div className="container about-page">
        <div className="text-zone">
          <h1 className='page-title '>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I'm a very ambitious full stack web developer looking for a role in an
            established company with the opportunity to work with the latest
            technologies on challenging and diverse projects.
          </p>
          <p align="LEFT">
            I'm quiet confident, naturally curious, and perpetually working on
            improving my chops one design problem at a time.
          </p>

        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={ faNode } color='#43853D' />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
    </m.section>
  )
}

export default About
